import React from 'react'

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Hooks
import useIndex from '../hooks/useIndex'

export default function Blog() {
  const { mode } = useIndex()

  return (
    <Layout currentPage="Blog" mode={mode}>
      <SEO title="Blog" />
      <div className={`container__blog container__blog--${mode}`}>
        <h1 className={`blog__coming-soon blog__coming-soon--${mode}`}>Coming Soon.</h1>
      </div>
    </Layout>
  )
}